'use strict';

// levels.js
export const levels = {
    1: {
        perTap: 1,
        perHour: 100,
        dogsNeeded: 1,
    },
    2: {
        perTap: 2,
        perHour: 200,
        dogsNeeded: 2,
    },
    3: {
        perTap: 10,
        perHour: 400,
        dogsNeeded: 3,
    },
    4: {
        perTap: 15,
        perHour: 500,
        dogsNeeded: 40,
    },
    5: {
        perTap: 20,
        perHour: 600,
        dogsNeeded: 50,
    },
    6: {
        perTap: 30,
        perHour: 700,
        dogsNeeded: 60,
    },
    7: {
        perTap: 30,
        perHour: 800,
        dogsNeeded: 70,
    },
    8: {
        perTap: 30,
        perHour: 900,
        dogsNeeded: 80,
    },
    9: {
        perTap: 30,
        perHour: 900,
        dogsNeeded: 90,
    },
    10: {
        perTap: 30,
        perHour: 1000,
        dogsNeeded: 100,
    },
    11: {
        perTap: 40,
        perHour: 1000,
        dogsNeeded: 110,
    },
    12: {
        perTap: 50,
        perHour: 1000,
        dogsNeeded: 120,
    },
    // Add more levels as needed
};

// Function to get level properties
function getLevelProperties(levelNumber) {
    return levels[levelNumber] || null; // Return null if the level doesn't exist
}

// Function to check for a new level and return the new level number
function checkForNewLevel(currentLevel, points, dogs) {
    let level = currentLevel;
    let levelData = getLevelProperties(level);

    if (levelData != null) {
        if (levelData.dogsNeeded <= dogs) {
            level = level + 1
        }
    }
    else {
        return 1;
    }

    return level; // Return the updated level
}


function canAddScore(maxPoints, points, last_seen, score_last_seen) {

    if ((points - score_last_seen) >= maxPoints) {
        // Get the current time in milliseconds
        const currentTime = Date.now(); // Current time in milliseconds

        // Convert `last_seen` (assume it's a timestamp in milliseconds) to a Date object
        const lastSeenTime = new Date(last_seen).getTime(); // Convert to milliseconds

        // Calculate the difference in milliseconds
        const timeDifference = currentTime - lastSeenTime;

        // Check if the difference is greater than 60 minutes (60 * 60 * 1000 ms)
        const sixtyMinutesInMilliseconds = 60 * 60 * 1000;

        if (timeDifference > sixtyMinutesInMilliseconds) {
            return true; // Can add score
        } else {
            return false; // Cannot add score
        }
    }
    else {
        return true
    }
}

async function getUserLastActivity(userId) {
    try {
        var apiGetUserLastActivity = "https://drdog.fleicom.com/getUserLastActivity.php?userID=" + userId;
        const response = await fetch(apiGetUserLastActivity); // Wait for the API response
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        // Parse the JSON response
        const data = await response.json(); // Wait for the response JSON to be retrieved
        console.log(data); // Output the response from the PHP file

        // Extract last_seen and score_last_seen
        const score_last_seen = data.score_last_seen || 0;
        const last_seen = data.last_seen || 0;


        // Return all values as an object
        return { score_last_seen, last_seen };

    }
    catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        // Return default values in case of an error
        return { score_last_seen: 0, last_seen: 0 };
    }
}
export { getLevelProperties, checkForNewLevel, getUserLastActivity, canAddScore };
