'use strict';
import './style.css'
import Phaser from 'phaser'
import ShareScene from './shareScene';
import EarnCoinsScene from './earnCoinsScene'
import UpgradeScene from './upgradeScene'
import { getLevelProperties, checkForNewLevel, getUserLastActivity, canAddScore } from './levels.js';

const sizes = {
  width: 650,
  height: 1280,
}
const speedDown = 100;
const coinsToDog = 100;

const topSizeAnimation = 0.2;
let topReached = false;
const bottomSizeAnimation = 0.1;


var name = "";
var last_name = "";
var user_name = "";
var userId = "";
var apiUrl = "";
var apiUrlScore = "";
var apiUrlScoreM1000 = "";
var apiGetUserData = "";
var apiUrlDogCoins = "";
export var apiGetUserLastActivity = "";
let points = 0;
let dogsCoins = 0;
let perTap = 1;
let perHour = 1;
var perHourEmpty = false;
let level = 1;
let score_last_seen = 0;
let lastSeenTime = 0;

var hasLevelAnnoucementAnimationFinished = true;



var levelData = getLevelProperties(level);


// Example of running a URL silently
function runUrlSilently(url) {
  try {
    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
        }
        return response.text();
      })
      .then(data => {
        console.log('Success:', data); // You can log the response or handle it
      })
      .catch(error => {
        // Catch any network errors (like server unreachable, etc.)
        console.log('Handled silently. Fetch failed but no error will be shown.');
      });
  }
  catch (err) {
    console.log("err")
  }
}

async function getUserData() {
  try {
    const response = await fetch(apiGetUserData); // Wait for the API response
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    // Parse the JSON response
    const data = await response.json(); // Wait for the response JSON to be retrieved
    console.log(data); // Output the response from the PHP file

    // Extract score, perhour, and pertap from the response
    const scoreDB = data.score || 0;
    const perhourDB = data.perhour || 0;
    const pertapDB = data.pertap || 0;
    const dogCoinDB = data.dog_coin || 0;
    const levelDB = data.level || 0;

    // check if new user, data not found in database
    if (perTap == 0) {

      return { score: 0, perHour: levelData.perHour, perTap: levelData.perTap, dogCoin: 0, level: 1 };
    }

    console.log(`User's score is: ${scoreDB}`);
    console.log(`User's per hour value is: ${perhourDB}`);
    console.log(`User's per tap value is: ${pertapDB}`);
    console.log(`User's dog coin value is: ${dogCoinDB}`);

    apiUrl = "https://drdog.fleicom.com/updateUser.php?userID=" + userId + "&name=" + name + "&last_name=" + last_name + "&user_name=" + user_name + "&score_last_seen=" + scoreDB;
    runUrlSilently(apiUrl);
    // Return all values as an object
    return { scoreDB, perhourDB, pertapDB, dogCoinDB, levelDB };
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error);
    // Return default values in case of an error
    return { scoreDB: 0, perhourDB: 0, pertapDB: 0, dogCoinDB: 0 };
  }

}



// Helper function to extract score from PHP response
function extractScoreFromResponse(responseText) {
  // Assuming the PHP returns something like: "User '12345' has a score of: 50."
  const match = responseText.match(/score of: (\d+)/);
  return match ? parseInt(match[1], 10) : null; // Extract and return the score as an integer
}

// get parameters from URL and save them into variables userName and userId
function getQueryParams() {
  const params = {};
  const queryString = window.location.search;  // Get the part of the URL after "?"

  console.log("Query string:", queryString);  // Debugging: Check what the query string looks like

  if (queryString) {
    const urlParams = new URLSearchParams(queryString);  // Create URLSearchParams object from query string

    // Iterate over each parameter and store it in the params object
    for (const [key, value] of urlParams.entries()) {
      params[key] = value;
      console.log(`Parameter found - ${key}: ${value}`);  // Debugging: Log each key-value pair
    }
  } else {
    console.log("No query parameters found.");
  }
  userId = params["id"]
  name = params["name"];
  last_name = params["last_name"]
  user_name = params["user_name"]

  if (user_name == null) {
    user_name = "not_available"
  }

  if (last_name == null) {
    last_name = "not_available"
  }


  apiUrlScore = "https://drdog.fleicom.com/updateScore.php?userID=" + userId;
  apiGetUserData = "https://drdog.fleicom.com/getUserData.php?userID=" + userId;
  //make api
  apiGetUserLastActivity = "https://drdog.fleicom.com/getUserLastActivity.php?userID=" + userId;
  apiUrlDogCoins = "https://drdog.fleicom.com/updateDogCoin.php?userID=" + userId
  apiUrlScoreM1000 = "https://drdog.fleicom.com/updateScoreM1000.php?userID=" + userId


  return params;
}


class BasicButton extends Phaser.GameObjects.Sprite {
  constructor(config) {

    //check if config contains a scene
    if (!config.scene) {
      console.log('missing scene');
      return;
    }
    //check if config contains a key
    if (!config.key) {
      console.log("missing key!");
      return;
    }
    //if there is no up property assume 0
    if (!config.up) {
      config.up = 0;
    }
    //if there is no down in config use up
    if (!config.down) {
      config.down = config.up;
    }
    //if there is no over in config use up
    if (!config.over) {
      config.over = config.up;
    }
    //call the constructor of the parent
    //set at 0,0 in case there is no x and y
    //in the config
    super(config.scene, 0, 0, config.key, config.up);

    //make a class level reference to the config
    this.config = config;


    //if there is an x assign it
    if (config.x) {
      this.x = config.x;
    }
    //if there is an x assign it
    if (config.y) {
      this.y = config.y;
    }

    if (config.width && config.height) {
      this.setDisplaySize(config.width, config.height);
    }
    //
    //add this to the scene
    config.scene.add.existing(this);
    //
    //make interactive and set listeners
    this.setInteractive();
    this.on('pointerdown', this.onDown, this);
    this.on('pointerup', this.onUp, this);
    this.on('pointerover', this.onOver, this);
    this.on('pointerout', this.onUp, this);
  }
  onDown() {
    this.setFrame(this.config.down);
  }
  onOver() {
    this.setFrame(this.config.over);
  }
  onUp() {
    this.setFrame(this.config.up);
  }
}

class GameScene extends Phaser.Scene {


  constructor() {
    super({
      key: 'GameScene'
    });
    this.player
    this.playerPressed
    this.cursor
    this.background;

    this.textScore;

    this.textTokens;
    this.textTokensTokens;
    this.textPerTap;
    this.textPerTapPerTap;
    this.textPerHour;
    this.textPerHourPerHour;

    this.textRemaining;

    this.username;
    this.levelText;
    this.dropSound;

    this.levelAnnouncement;
    this.glow;
    this.emitter;
    this.dogCoinEmitter;
    this.button;
    this.buttonBack;
    this.buttonBack2;
    this.squareButton;
    this.squareTokenBack;
    this.squarePerTapBack;
    this.squarePerHourBack;
    this.bitcoin;
    this.bitcoinTap;
    this.bitcoinHour;
    this.dogPressed;
    this.dogCoin;


    this.lightEffect;
    this.navBack;
    this.navigationHome;
    this.navigationReferral;
    this.navigationUpgrades;
    this.navigationEarn;
  }
  preload() {

    getQueryParams();
    this.load.image("back", "/assets/back.png");
    this.load.image("test", "/assets/dog.png");
    this.load.image("ball", "/assets/ball.png");
    this.load.image("bitcoin", "/assets/bitcoin.png");
    this.load.image("backbutton", "/assets/buttonback.png");
    this.load.image("dogPressed", "/assets/dogpressed.png");
    this.load.image("homenav", "/assets/homenav.png");
    this.load.image("earnnav", "/assets/earnnav.png");
    this.load.image("groupsnav", "/assets/groupsnav.png");
    this.load.image("upgradesnav", "/assets/upgradesnav.png");
    this.load.image("homenavactive", "/assets/homenavactive.png");
    this.load.image("earnnavactive", "/assets/earnnavactive.png");
    this.load.image("groupsnavacitve", "/assets/groupsnavactive.png");
    this.load.image("upgradesnavacitve", "/assets/upgradesnavavtive.png");
    this.load.image("lightEffect", "/assets/lighteffect.png");
    this.load.image("bitcoinLite", "/assets/coinlight.png");
    this.load.audio("drop", "/assets/sounds/drop.wav");
    this.load.image("dogCoin", "/assets/dogcoin.png");
    this.load.image("glow", "/assets/glow.png")
    this.load.image("square2", "/assets/square2.png")
    this.load.image("plus1", "/assets/plus1.png")
    this.load.image("navback", "/assets/navback.png")
  }

  async create() {


    this.dropSound = this.sound.add("drop");

    //light effect
    this.lightEffect = this.add.image(0, 75, "lightEffect").setOrigin(0, 0).setDisplaySize(sizes.width, 60);
    this.lightEffect = this.add.image(0, sizes.height - 130, "lightEffect").setOrigin(0, 0).setDisplaySize(sizes.width, 10);

    this.background = this.add.image(0, -205, "back").setDisplaySize(sizes.width, sizes.height + 200).setOrigin(0, 0);

    this.player = this.physics.add.image(sizes.width / 2 - 200, sizes.height / 2 - 50, "test").setOrigin(0, 0);
    this.player.setInteractive();
    this.player.on('pointerdown', this.onDown, this);
    this.player.on('pointerup', this.onUp, this);
    this.tweens.add({
      targets: this.player,
      alpha: { from: 0, to: 1 },
      ease: 'Sine.InOut',
      duration: 5000,
      repeat: 0,
      yoyo: false
    });

    this.player.setDisplaySize(400, 400).setOffset(0, 0);
    this.player.setImmovable = true;
    this.player.body.allowGravity = false;
    this.player.setCollideWorldBounds(true);

    this.playerPressed = this.physics.add.image(sizes.width / 2 - 200, sizes.height / 2 - 50, "dogPressed").setOrigin(0, 0);
    this.playerPressed.setInteractive();
    this.playerPressed.on('pointerdown', this.onDown, this);
    this.playerPressed.on('pointerup', this.onUp, this);
    this.playerPressed.removeFromDisplayList();

    this.playerPressed.setDisplaySize(400, 400).setOffset(0, 0);
    this.playerPressed.setImmovable = true;
    this.playerPressed.body.allowGravity = false;
    this.playerPressed.setCollideWorldBounds(true);

    this.cursor = this.input.keyboard.createCursorKeys();


    this.squareTokenBack = this.add.image(120, 180, "square2").setDisplaySize(180, 150);
    this.squarePerTapBack = this.add.image(120 + this.squareTokenBack.displayWidth + 22, 180, "square2").setDisplaySize(this.squareTokenBack.displayWidth, this.squareTokenBack.displayHeight);
    this.squarePerHourBack = this.add.image(120 + this.squareTokenBack.displayWidth * 2 + 20 * 2, 180, "square2").setDisplaySize(this.squareTokenBack.displayWidth, this.squareTokenBack.displayHeight);
    this.bitcoin = this.add.image(sizes.width / 2 - 70, 350, "bitcoin").setDisplaySize(60, 60);
    this.bitcoinTap = this.add.image(sizes.width / 2 - 120, 350, "bitcoin").setDisplaySize(60, 60);
    this.bitcoinHour = this.add.image(sizes.width / 2 - 100, 350, "bitcoin").setDisplaySize(60, 60);
    //dog coin gloww

    this.dogCoin = this.add.image(this.bitcoin.x, this.bitcoin.y + 100, "dogCoin").setDisplaySize(80, 80);

    this.bitcoin.setImmovable = true;
    this.dogCoin.setImmovable = true;
    this.dogCoin.setInteractive()
    this.dogCoin.on('pointerdown', this.onDogCoinDown, this);

    //bottom nav buttons
    this.navBack = this.add.image(0, sizes.height - 100, "navback").setOrigin(0, 0).setDisplaySize(sizes.width, 100);
    this.navigationHome = this.add.image(30, sizes.height - 85, "homenavactive").setOrigin(0, 0).setDisplaySize(45, 45);
    this.navigationHome.setInteractive();
    this.navigationHome.on('pointerdown', () => this.transitionToScene('GameScene'));
    this.add.text(this.navigationHome.x - 15, this.navigationHome.y + this.navigationHome.displayHeight, "Exchange", { font: "20px helvetica bold", fill: "#9A9A9A" });
    5
    this.navigationUpgrades = this.add.image(sizes.width / 3 - 10, sizes.height - 85, "upgradesnav").setOrigin(0, 0).setDisplaySize(45, 45);
    this.navigationUpgrades.setInteractive();
    this.navigationUpgrades.on('pointerdown', () => this.transitionToScene('UpgradeScene'));
    this.add.text(this.navigationUpgrades.x - 15, this.navigationUpgrades.y + this.navigationUpgrades.displayHeight, "Upgrades", { font: "20px helvetica bold", fill: "#9A9A9A" });

    this.navigationReferral = this.add.image(sizes.width / 3 * 2 - 30, sizes.height - 85, "groupsnav").setOrigin(0, 0).setDisplaySize(45, 45);
    this.navigationReferral.setInteractive();
    this.navigationReferral.on('pointerdown', () => this.transitionToScene('EarnCoinsScene'));
    this.add.text(this.navigationReferral.x - 15, this.navigationReferral.y + this.navigationReferral.displayHeight, "Referral", { font: "20px helvetica bold", fill: "#9A9A9A" });

    this.navigationEarn = this.add.image(sizes.width - 90, sizes.height - 85, "earnnav").setOrigin(0, 0).setDisplaySize(45, 45);
    this.navigationEarn.setInteractive();
    this.navigationEarn.on('pointerdown', () => this.transitionToScene('ShareScene'))
    this.add.text(this.navigationEarn.x - 20, this.navigationEarn.y + this.navigationEarn.displayHeight, "Earn more", { font: "20px helvetica bold", fill: "#9A9A9A" });

    // text remaining text
    this.textRemaining = this.add.text(sizes.width / 2 - 110, 520, coinsToDog - parseInt(points) + " coins needed!", { font: "25px helvetica bold", fill: "#ffffff" });

    // Score text
    this.textScore = this.add.text(sizes.width / 2 - 20, this.bitcoin.y - 35, points, { font: "70px helvetica bold", fill: "#ffffff" });

    // text in top 3 square backgrounds
    this.textTokens = this.add.text(this.squareTokenBack.x - 15, this.squareTokenBack.y, dogsCoins, { font: "35px helvetica bold", fill: "#ffffff" });
    this.textTokensTokens = this.add.text(this.squareTokenBack.x - 35, this.squareTokenBack.y - 60, "Tokens", { font: "25px helvetica bold", fill: "#ffffff" });
    this.textPerTap = this.add.text(this.squarePerTapBack.x - 10, this.squarePerTapBack.y, "+" + perTap, { font: "35px helvetica bold", fill: "#ffffff" });
    this.textPerTapPerTap = this.add.text(this.squarePerTapBack.x - 35, this.squarePerTapBack.y - 60, "Per tap", { font: "25px helvetica bold", fill: "#ffffff" });
    this.textPerHour = this.add.text(this.squarePerHourBack.x - 10, this.squarePerHourBack.y, "+" + perHour, { font: "35px helvetica bold", fill: "#ffffff" });
    this.textPerHourPerHour = this.add.text(this.squarePerHourBack.x - 35, this.squarePerHourBack.y - 60, "Per hour", { font: "25px helvetica bold", fill: "#ffffff" });

    this.dogCoin.x = this.squareTokenBack.x - 35 - this.textTokens.displayWidth / 2
    this.dogCoin.y = this.squareTokenBack.y + 20;
    this.dogCoin.setDisplaySize(40, 40);

    this.bitcoinTap.x = this.squarePerTapBack.x - this.textPerTap.displayWidth / 2 - 20
    this.bitcoinTap.y = this.squarePerTapBack.y + 20;
    this.bitcoinTap.setDisplaySize(40, 40);

    this.bitcoinHour.x = this.squarePerHourBack.x - this.textPerHour.displayWidth / 2 - 20
    this.bitcoinHour.y = this.squarePerHourBack.y + 20;
    this.bitcoinHour.setDisplaySize(40, 40);

    //gdd glow
    this.glow = this.add.image(this.dogCoin.x, this.dogCoin.y, "glow").setOrigin(0.5, 0.5).setDisplaySize(200, 200).setVisible(false)
    this.glow.setBelow(this.dogCoin);

    //set lighteffect behind backgroudn
    this.lightEffect.setBelow(this.background)

    // Score text
    this.levelAnnouncement = this.add.text(-600, 450, " LEVEL " + level, { font: "150px helvetica bold", fill: "#ffffff" });

    //move coin in case of large number
    this.bitcoin.x = this.bitcoin.x - this.textScore.scaleX
    // userName text
    var un = ""
    if (name != "NaN") {
      un = name;
    }
    if (last_name != "NaN" && last_name != "not_available") {
      un = un + " " + last_name;
    }
    this.username = this.add.text(30, 35, un, { font: "25px helvetica bold", fill: "#ffffff" });
    this.levelText = this.add.text(sizes.width / 2 - 20, 35, "Level: " + level, { font: "25px helvetica bold", fill: "#ffffff" });


    this.emitter = this.add.particles(0, 0, "plus1",
      {
        speed: 200,
        gravity: 0,
        scale: { start: 1.2, end: 0 }, end: 0,
        duration: 200,
        lifespan: 2000,
        emitting: true,
        follow: this.player


      })
    this.emitter.setPosition(this.player.x + 100, this.player.y - 380);



    //emitter on converting coins to dr. dog
    this.dogCoinEmitter = this.add.particles(100, 100, "dogCoin",
      {
        speed: 200,
        gravity: speedDown - 500,
        scale: { start: 0.3, end: 0 }, end: 0,
        duration: 300,
        lifespan: 800,
        emitting: true,

      })

    this.dogCoinEmitter.setPosition(this.dogCoin.x, this.dogCoin.y);



    window.Telegram.WebApp.expand()
    /*     BUTTON EXAMPLE
  this.button = new BasicButton({
    'scene': this,
    'key': 'bitcoin',
    'up': 0,
    'over': 0,
    'down': 0,
    'x': sizes.width / 4 - 20,
    'y': sizes.height - sizes.height / 9,
    'width': 100,
    'height': 100
  });
  */

    const data = await getUserData();
    const dataLastActivity = await getUserLastActivity(userId);

    points = data.scoreDB
    dogsCoins = data.dogCoinDB
    perTap = data.pertapDB
    perHour = data.perhourDB
    level = data.levelDB

    score_last_seen = dataLastActivity.score_last_seen;
    lastSeenTime = dataLastActivity.last_seen;

    this.cameras.main.fadeIn(1000, 0, 0, 0);

  }


  update() {


    this.textPerTap.text = "+" + perTap
    this.textPerHour.text = "+" + perHour
    level = checkForNewLevel(level, points, dogsCoins)
    this.levelText.text = "Level:" + level
    this.levelText.x = sizes.width - this.levelText.displayWidth - 10


    this.player.setBelow(this.emitter);
    this.playerPressed.setBelow(this.emitter);
    this.emitter.setAlpha(0.5)

    this.textScore.setText(points);

    this.textTokens.setText(dogsCoins);
    if (coinsToDog - points > 0) {
      this.textRemaining.setText(coinsToDog - points + " coins needed!")
    }
    else {
      this.textRemaining.setText("Convert coins to Dr. Dog!")
    }

    // animate new level
    // do animation until finished
    this.doLevelAnnouncement();


    if (points >= coinsToDog) {

      //glow rotation
      this.glow.angle++
      //dogCoin resizing animation
      this.resizingAnimation(this.dogCoin)

      //add glow
      this.tweens.add({
        targets: this.glow,
        alpha: { from: 0, to: 1 },
        ease: 'Sine.InOut',
        duration: 2000,
        repeat: 0,
        yoyo: false
      });

      this.glow.setVisible(true)
    }

  }

  doLevelAnnouncement() {
    if (!this.hasLevelAnnoucementAnimationFinished) {
      this.levelAnnouncement.setText("LEVEL " + level)
      // Increment the scale
      this.levelAnnouncement.x = this.levelAnnouncement.x + 6

      // Check if the scale has exceeded the threshold
      if (this.levelAnnouncement.x > sizes.width + 500) {
        this.hasLevelAnnoucementAnimationFinished = true;
      }
    } else {
      // Reset position 
      this.levelAnnouncement.x = - 700

    }
  }

  resizingAnimation(image) {

    // Use a small value to check scale range (for floating-point precision)
    const precision = 0.0001;

    // Check if we should scale up or down based on the `topReached` status
    if (!topReached) {
      image.setScale(image.scale + 0.001);
      // If we reach or exceed the top size, toggle direction
      if (image.scale >= topSizeAnimation - precision) {
        topReached = true;
      }
    } else {
      image.setScale(image.scale - 0.0008);
      // If we reach or go below the bottom size, toggle direction
      if (image.scale <= bottomSizeAnimation + precision) {
        topReached = false;
      }
    }

  }


  //Convert coins to Dr Dog
  onDogCoinDown(sprite, pointer) {

    if (points >= coinsToDog) {
      // start emmiter effect
      this.dogCoinEmitter.start()

      //update drDog coins in database
      runUrlSilently(apiUrlDogCoins)

      //substract 1000 coins in database
      runUrlSilently(apiUrlScoreM1000)

      points = points - coinsToDog
      dogsCoins = dogsCoins + 1

      //check for new level
      let oldLevel = level
      level = checkForNewLevel(level, points, dogsCoins)

      levelData = getLevelProperties(level)

      // trigger animation
      if (level != oldLevel) {
        hasLevelAnnoucementAnimationFinished = false;
      }

      //hide glow
      this.tweens.add({
        targets: this.glow,
        alpha: { from: 1, to: 0 },
        ease: 'Sine.InOut',
        duration: 3000,
        repeat: 0,
        yoyo: false
      });

    }
  }



  // on main button press
  onDown(sprite, pointer) {
    //update text in perHour top field
    perHour = levelData.perHour - (points - score_last_seen)
    if (perHour < 0) {
      perHour = 0;
    }

    if (canAddScore(levelData.perHour, points, lastSeenTime, score_last_seen) == true) {
      points = points + perTap;

      this.player.removeFromDisplayList();
      this.playerPressed.addToDisplayList();
      this.emitter.start();
      level = checkForNewLevel(level, points, dogsCoins)
      levelData = getLevelProperties(level)

      perHour = levelData.perHour - (points - score_last_seen)
      if (perHour < 0) {
        perHour = 0;
      }

      perTap = levelData.perTap
      runUrlSilently(apiUrlScore + "&scoreIncrement=" + perTap);
    }
  }

  // on main button release
  onUp(sprite, pointer) {
    this.player.addToDisplayList();
    this.playerPressed.removeFromDisplayList();
  }

  //bottom navigation button, invite 
  onEarnDown(sprite, pointer) {
    this.scene.start('EarnCoinsScene');
  }
  onEarnUp(sprite, pointer) {
    this.player.addToDisplayList();
    this.playerPressed.removeFromDisplayList();
  }


  onShareDown(sprite, pointer) {
    // Switch to ShareScene
    this.scene.start('ShareScene');
  }
  onShareUp(sprite, pointer) {
    this.player.addToDisplayList();
    this.playerPressed.removeFromDisplayList();
  }

  targetHit() {
    this.enemy.x = this.getRandomX();
    this.enemy.y = 0;
    points++;
    console.log(points);
    this.textScore.setText(this.points);
    this.dropSound.play();
    this.emitter.start();
  }

  getRandomX() {
    return Math.floor(Math.random() * sizes.width);
  }

  gameOver() {
    console.log("Game over!");
  }

  transitionToScene(targetScene) {
    this.cameras.main.fadeOut(300, 0, 0, 0);  // Fade out over 1 second (1000ms)

    this.time.delayedCall(300, () => {  // Wait for fade-out to complete
      this.scene.start(targetScene);   // Start the new scene
    });
  }
}

const Config = {


  type: Phaser.WEBGL,
  width: sizes.width,
  height: sizes.height,
  scale: {
    // Fit to window
    mode: Phaser.Scale.FIT,
    // Center vertically and horizontally
    autoCenter: Phaser.Scale.CENTER_BOTH
  },
  canvas: gameCanvas,
  physics: {
    default: "arcade",
    arcade: {
      gravity: {
        y: speedDown
      },
      debug: false
    }
  },
  scene: [GameScene, ShareScene, EarnCoinsScene, UpgradeScene]
}

const game = new Phaser.Game(Config);
export default GameScene;



